import { useRouter } from "next/router";
import { jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { ButtonJack } from "../../components/ButtonsJack/parent";
import { kybPathnames, useIsKYBReviewed } from "./header";
import { localUserStatusBoolean, useGetAuth } from "../../contexts/AuthContext";
import { windowDimension } from "../../components/tools";
import { useRef } from "react";
import { eventsTracker } from "../../universalFunctions/events";
import { useTranslation } from "react-i18next";
import { useLanguage } from "public/locales/translationFunctions";

export const BackKybButton = ({ isLoading, isSubmit, onBack }) => {
  const { back, push, pathname, query } = useRouter();
  const { isSecond } = query;
  const { t } = useTranslation("common");
  const {
    bankPathname,
    detailsPathname,
    representativePathname,
    onboardPathname,
    structurePathname,
    supportingPathname,
  } = kybPathnames();

  const { user } = useGetAuth();
  const { isReview } = useIsKYBReviewed();
  const { isWaitingVerification, isPending } = localUserStatusBoolean(user);

  const onClick = () => {
    if (onBack) return onBack();
    eventsTracker("kyb_back");
    const boolean = (path) => pathname.includes(path);

    if (isWaitingVerification || isPending || isReview) return back();

    if (boolean(supportingPathname)) return push(structurePathname);
    if (boolean(structurePathname)) {
      if (isSecond) return push(structurePathname);
      return push(representativePathname);
    }
    if (boolean(representativePathname)) return push(bankPathname);
    if (boolean(bankPathname)) return push(detailsPathname);
    if (boolean(detailsPathname)) return push(onboardPathname);

    return back();
  };
  return (
    <ButtonJack
      isLoading={isLoading}
      onClick={onClick}
      leftIcon={
        !isSubmit && (
          <JackIcons
            name="arrow-back-outline"
            fill={isLoading ? jackColors.greyBB : jackColors.black34}
          />
        )
      }
      style={{
        width: isSubmit ? 104 : 92,
        minWidth: isSubmit ? 104 : 92,
        height: 40,
      }}
      type="outline"
      buttonType="button"
      isSmall
    >
      {isSubmit ? 
      t("Cancel") : t("Back")}
    </ButtonJack>
  );
};

export const NextAndBack = ({
  onClick,
  disabled,
  isSubmit,
  isSubmitReal,
  isLoading,
  woBack = false,
  onBack,
  woSubmit,
  nextText = "Next",
}) => {
  const { isBahasa: isID } = useLanguage();
  const { t: tLocal } = useTranslation("local-transfer/revamp");
  const { t } = useTranslation("common");

  const width = () => {
    if (isSubmitReal) return 96;
    if (isSubmit) return 104;
    return isID ? 112 : 79;
  };
  const { isTabOrPhone } = windowDimension();
  const ref = useRef();
  const height = (ref?.current?.clientHeight || 40) + 16;
  const phoneObj = isTabOrPhone
    ? {
        position: "fixed",
        bottom: -20,
        left: 0,
        width: "100vw",
        padding: 16,
        paddingBottom: 32,
        backgroundColor: "white",
        zIndex: 100,
        borderTop: `1px solid ${jackColors.greyE6}`,
      }
    : {};

  const nextTextDecider = () => {
    if (!!nextText) return tLocal(nextText);
    if (isSubmitReal) return tLocal("Submit");
    if (isSubmit) return tLocal("Save");
    return tLocal("Next");
  };

  return (
    <>
      {isTabOrPhone && <div style={{ height }} />}
      <div
        ref={ref}
        className="d-flex justify-content-end"
        style={{
          marginTop: 32,
          marginBottom: 48,
          ...phoneObj,
        }}
      >
        {!woBack && (
          <BackKybButton
            isLoading={isLoading}
            isSubmit={isSubmit}
            onBack={onBack}
          />
        )}
        {!woSubmit && (
          <ButtonJack
            isLoading={isLoading}
            isSmall
            onClick={onClick}
            buttonType="submit"
            disabled={disabled}
            style={{
              width: width(),
              minWidth: width(),
              marginLeft: 16,
              height: 40,
            }}
            rightIcon={
              !isSubmit && (
                <JackIcons
                  name="arrow-forward"
                  fill={
                    disabled || isLoading
                      ? jackColors.greyBB
                      : jackColors.black34
                  }
                />
              )
            }
          >
            {nextTextDecider()}
          </ButtonJack>
        )}
      </div>
    </>
  );
};
